import supportedBrowsers from '@/utils/supportedBrowsers'

export function usePwaNotification() {
  const { $pwa } = useNuxtApp()

  onMounted(() => {
    if (!supportedBrowsers.test(navigator.userAgent)) {
      showToast(
        'security',
        'Browser Desatualizado',
        'O seu browser enconta-se desatualizado. Para assegurar a sua expêriencia e segurança, atualize o seu navegador.',
        0,
      )
    }
  })

  watch(
    () => $pwa?.needRefresh,
    (newVal) => {
      if (!newVal) return

      showToast(
        'update',
        'Atualização Disponível',
        'Uma nova atualização com as últimas correções e funcionalidades, encontra-se disponível.',
        0,
        [
          {
            label: 'Reiniciar Aplicação',
            color: 'primary',
            variant: 'soft',
            click: () => $pwa?.updateServiceWorker(),
          },
        ],
      )
    },
    {
      immediate: true,
    },
  )
}
