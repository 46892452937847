import payload_plugin_dHWDzdkIDW from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.31.0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_i0Iak8eHDl from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.19.0_jiti@2.4.2__io_6dxz2w3ajwrh7vp5z3aglinxom/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_uv9jTHlqzc from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.19.0_jiti@2.4.2__io_6dxz2w3ajwrh7vp5z3aglinxom/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_HkFw86TCBz from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.19.0_jiti@2.4.2__io_6dxz2w3ajwrh7vp5z3aglinxom/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_RdkmpZ1aLI from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.19.0_jiti@2.4.2__io_6dxz2w3ajwrh7vp5z3aglinxom/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_UfIHRquAGg from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.19.0_jiti@2.4.2__io_6dxz2w3ajwrh7vp5z3aglinxom/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_YszkKUh3RJ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.19.0_jiti@2.4.2__io_6dxz2w3ajwrh7vp5z3aglinxom/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_PEUO1YAbSj from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.19.0_jiti@2.4.2__io_6dxz2w3ajwrh7vp5z3aglinxom/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_UtRw2ejqZa from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.31.0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_YD9JGibpMI from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.19.0_jiti@2.4.2__io_6dxz2w3ajwrh7vp5z3aglinxom/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_rVXzFqkWwl from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_magicast@0.3.5_rollup@4.31.0_typescript@5.7.3_vite@6.0.11_@_x4pcoamjlibxxhm3hfn734udl4/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_05J3B7omMy from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_magicast@0.3.5_rollup@4.31.0_typescript@5.7.3_vite@6.0.11_@_x4pcoamjlibxxhm3hfn734udl4/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_iG2MMCrmOH from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_magicast@0.3.5_rollup@4.31.0_typescript@5.7.3_vite@6.0.11_@_x4pcoamjlibxxhm3hfn734udl4/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_biaJZEQhc6 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.31.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_ff8hBhW2Op from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.31.0_vite@6.0.11_@types+node@22.10.10_jiti@2.4.2_sa_2asmnjawagcleg3lkgqh5v3lhu/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import formkitPlugin_pZqjah0RUG from "/opt/buildhome/repo/.nuxt/formkitPlugin.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/opt/buildhome/repo/.nuxt/pwa-icons-plugin.ts";
import pwa_client_jtQBV432Kz from "/opt/buildhome/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_@vite-pwa+assets-generator@0.2.6_magicast@0.3.5_rollup@4.31.0_vite@6.0._7s56qgtypw7lf4gsftg4qxttgy/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import _01_api_GIiciWaEWX from "/opt/buildhome/repo/plugins/01.api.ts";
import _02_nuxtClientInit_client_42PBK3TMYu from "/opt/buildhome/repo/plugins/02.nuxtClientInit.client.ts";
import dayjs_pGiXRjcsJO from "/opt/buildhome/repo/plugins/dayjs.ts";
import sentry_client_shVUlIjFLk from "/opt/buildhome/repo/plugins/sentry.client.ts";
export default [
  payload_plugin_dHWDzdkIDW,
  revive_payload_client_i0Iak8eHDl,
  unhead_uv9jTHlqzc,
  router_HkFw86TCBz,
  payload_client_RdkmpZ1aLI,
  navigation_repaint_client_UfIHRquAGg,
  check_outdated_build_client_YszkKUh3RJ,
  chunk_reload_client_PEUO1YAbSj,
  plugin_vue3_UtRw2ejqZa,
  components_plugin_KR1HBZs4kY,
  prefetch_client_YD9JGibpMI,
  slideovers_rVXzFqkWwl,
  modals_05J3B7omMy,
  colors_iG2MMCrmOH,
  plugin_client_biaJZEQhc6,
  plugin_ff8hBhW2Op,
  formkitPlugin_pZqjah0RUG,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_jtQBV432Kz,
  _01_api_GIiciWaEWX,
  _02_nuxtClientInit_client_42PBK3TMYu,
  dayjs_pGiXRjcsJO,
  sentry_client_shVUlIjFLk
]