export const appHead = {"meta":[{"hid":"charset","charset":"utf-8"},{"hid":"viewport","name":"viewport","content":"width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"},{"hid":"mobile-web-app-capable","name":"mobile-web-app-capable","content":"yes"},{"hid":"apple-mobile-web-app-capable","name":"apple-mobile-web-app-capable","content":"yes"},{"hid":"apple-mobile-web-app-status-bar-style","name":"apple-mobile-web-app-status-bar-style","content":"default"},{"hid":"apple-mobile-web-app-title","name":"apple-mobile-web-app-title","content":"DentalNext"},{"hid":"author","name":"author","content":"DentalNext"},{"hid":"description","name":"description","content":"Gestão de Clínicas Dentárias"},{"hid":"msapplication-TileColor","name":"msapplication-TileColor","content":"#0ea5e9"},{"hid":"og:type","name":"og:type","property":"og:type","content":"website"},{"hid":"og:title","name":"og:title","property":"og:title","content":"DentalNext"},{"hid":"og:site_name","name":"og:site_name","property":"og:site_name","content":"DentalNext"},{"hid":"og:description","name":"og:description","property":"og:description","content":"Gestão de Clínicas Dentárias"}],"link":[{"rel":"preconnect","href":"https://api-prod.dentalnext.pt"},{"rel":"preconnect","href":"https://c97379466e1c16ecaaa16f48201d0f3f.eu.r2.cloudflarestorage.com/"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"pt"}}

export const appKeepalive = {"max":5}

export const appPageTransition = false

export const appLayoutTransition = false

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appViewTransition = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = true

export const spaLoadingTemplateOutside = false