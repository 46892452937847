import validate from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.19.0_jiti@2.4.2__io_l3dqeiixml3ura4r5hruoald24/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/opt/buildhome/repo/middleware/auth.global.ts";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.19.0_jiti@2.4.2__io_l3dqeiixml3ura4r5hruoald24/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "has-permission": () => import("/opt/buildhome/repo/middleware/hasPermission.ts"),
  "is-clinic-admin": () => import("/opt/buildhome/repo/middleware/isClinicAdmin.ts")
}